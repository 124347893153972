<template>
  <div class="wrapper">
    <b-row class="upper-row">
      <b-col cols="6" class="mt-2">
        <p style="font-weight: 300">{{ $t("page.results.score.incontextMetrics.ecmp.calculateECMP.setOriginalECMP") }}</p>
      </b-col>
      <b-col cols="6">
        <b-form-input
          v-model="value"
          placeholder="Input ecpm"
          @input="calculator"
        ></b-form-input>
      </b-col>
    </b-row>

    <hr />
    <div class="down-row">
      <b-row class="align-center">
        <b-col cols="6 d-flex align-items-center">
          <p style="font-weight: 300" class="m-0">eCPM</p>
        </b-col>
        <b-col cols="6 ">
          <h2 class="above-norm m-0"><span>$ </span>{{ ecmp }}</h2>
        </b-col>
      </b-row>
      <b-row class="align-center mt-4">
        <b-col cols="6 d-flex align-items-center">
          <p style="font-weight: 300" class="m-0">
            {{ $t("page.results.score.incontextMetrics.ecmp.calculateECMP.attentivePer000") }}
          </p>
        </b-col>
        <b-col cols="6" style="color: #7f7f7f">
          <h2 class="m-0">{{ attentiveSec }} <span> {{ $t("page.results.sec") }}</span></h2>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    efficiency: {
      required: true,
      type: Number,
    },
    attentiveSec:{
      required:true,
      type:Number,
    }
  },
  data() {
    return {
      ecmp: 0,
      value: 0,
    };
  },

  methods: {
    calculator() {
      if (!this.value) {
        this.ecmp = 0;
      }
      this.ecmp = (this.value / this.efficiency).toFixed(2);
    },
  },
  computed: {
    getECMP() {
      return this.ecmp;
    },
    getAttentiveSec() {
      return this.attentiveSec;
    },
  },
};
</script>
<style scoped>
.wrapper {
  background-color: #f8f9fa;
  border-radius: 5px;
}
.upper-row {
  padding: 20px 20px 0px 20px;
  display: flex;
  justify-content: space-around;
}
.down-row {
  padding: 20px 20px 30px 20px;
}

.above-norm {
  color: #7aae30;
}
.below-norm {
  color: #ff0000;
}
.close-norm {
  color: #f3ad57;
}
.align-center {
  align-items: center;
}
</style>
